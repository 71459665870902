import React, { memo } from 'react'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { RootState } from '../store'
import { ModalGeneral } from './ModalGeneral'
import { ModalLicense } from './ModalLicense'
import { ModalMetaTraderUsers } from './ModalMetaTraderUsers'
import { ModalType } from './modalsEnum'
import { ModalSettingServers } from './ModalSettingServers'

export const ModalContainer: React.FC = memo(() => {
  const { modalName } = useTypedSelector((state: RootState) => state.modal)

  let modalComponent = null

  if (modalName === ModalType.License) {
    modalComponent = <ModalLicense />
  } else if (modalName === ModalType.SettingServers) {
    modalComponent = <ModalSettingServers />
  } else if (modalName === ModalType.General) {
    modalComponent = <ModalGeneral />
  } else if (modalName === ModalType.MetaTraderUsers) {
    modalComponent = <ModalMetaTraderUsers />
  }

  return <>{modalComponent}</>
})
