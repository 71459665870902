import React, { useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useActions } from '../hooks/useActions'
import { useFormValidation } from '../hooks/useFormValidation'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { buildControlsExtTwoPerLine, timeInput } from '../inputs/controls'
import { RootState } from '../store'

export const ModalSettingServers = () => {
  const { hideModal, reloadMtServersItemId } = useActions()
  const { modalData } = useTypedSelector((state: RootState) => state.modal)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation({ From: new Date() }, {})

  const handleClose = useCallback(() => hideModal(), [hideModal])
  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />

  const handleYes = () => {
    modalData.onYes()
    reloadMtServersItemId(modalData.item.serverId, inputState.From.toISOString())
    handleClose()
  }

  const handleNo = () => {
    handleClose()
  }

  const inputsServersSettings = buildControlsExtTwoPerLine(
    [timeInput('From', { maxDate: true })],
    inputState,
    setInputState,
    'ServerSettings',
    touched,
    setTouched,
    errors,
    '',
    true,
  )

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <Modal.Body>
        <div className="test">{inputsServersSettings}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes}>
          <FormattedMessage id="yes" />
        </Button>
        <Button onClick={handleNo}>
          <FormattedMessage id="no" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
