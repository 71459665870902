import { IMultiSelectItem } from '../models/IMultiSelectItem'
import { buildSelectOption } from '../utils/buildSelectOptions'

export class Symbol {
  name: string
  assetType: IMultiSelectItem
  executionType: IMultiSelectItem
  derivativeType: IMultiSelectItem
  isManuallyTyped: boolean
  isOverTheCounter: boolean

  static schema = {}

  constructor(item?: any) {
    this.name = item.name ?? ''
    this.assetType = {
      value: item.assetType ?? 'Commodities',
      label: item.assetType === 'ForexOrCurrencies' ? 'Forex or currencies' : item.assetType === 'StockOrEquity' ? 'Stock or equity' : 'Commodities',
    }
    this.executionType = buildSelectOption(item.executionType, 'CFD')
    this.derivativeType = buildSelectOption(item.derivativeType, 'None')
    this.isManuallyTyped = item.isManuallyTyped ?? false
    this.isOverTheCounter = item.isOverTheCounter ?? false
  }
}
