import { IServersItemId, IServersMt, ITimezone } from '../../../models/IServers'

export enum ServersMtActionEnum {
  SET_SERVERSMT = 'SET_SERVERSMT',
  SET_SERVERSITEMID = 'SET_SERVERSITEMID',
  SET_TIMEZONE = 'SET_TIMEZONE',
}

export interface ServersState {
  ServersMt: IServersMt[]
  ServersItemId: IServersItemId
  Timezone: ITimezone[]
  totalItems: number
}

export interface SetServersAction {
  type: ServersMtActionEnum.SET_SERVERSMT
  payload: {
    items: IServersMt[]
    totalItems: number
  }
}

export interface SetServerIdAction {
  type: ServersMtActionEnum.SET_SERVERSITEMID
  payload: IServersItemId
}

export interface SetTimezoneAction {
  type: ServersMtActionEnum.SET_TIMEZONE
  payload: ITimezone[]
}

export type ServersAction = SetServersAction | SetServerIdAction | SetTimezoneAction
