import axios, { AxiosResponse } from 'axios'
import { IGetUsers, IRefreshToken } from '../models/IAuth'
import { api, buildHeaders } from './apiUtils'

export class AuthService {
  static async getConfig(): Promise<AxiosResponse<{ apiPath: string }>> {
    return axios.get(`../config.json`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
  }

  static async getVersion(): Promise<AxiosResponse<{ bbiWebApiVersion: string }>> {
    return axios.get(`${api()}/AppInfo/GetVersion`, {
      headers: buildHeaders(),
    })
  }

  static async getUsers(username: string, password: string): Promise<AxiosResponse<IGetUsers>> {
    return axios.post(
      `${api()}/Auth`,
      { login: username, password },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      },
    )
  }

  static async refreshToken(username: string, refreshToken: string): Promise<AxiosResponse<IRefreshToken>> {
    return axios.post(
      `${api()}/Auth/RefreshToken`,
      { userName: username, refreshToken },
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      },
    )
  }
}
