import { lazy } from 'react'
import { IRoute, RouteNames } from '../models/IRoute'

const FinancialInstruments = lazy(() => import(`../pages/FinancialInstruments/FinancialInstruments`).then(module => ({ default: module.FinancialInstruments })))
const Settings = lazy(() => import(`../pages/Settings`).then(module => ({ default: module.Settings })))
const MetaTraderUsers = lazy(() => import(`../pages/MetaTraderUsers/MetaTraderUsers`).then(module => ({ default: module.MetaTraderUsers })))

export const privateRoutes: IRoute[] = [
  {
    path: RouteNames.FINANCIAL_INSTRUMENTS,
    exact: true,
    component: FinancialInstruments,
  },
  {
    path: RouteNames.META_TREADER_USERS,
    exact: true,
    component: MetaTraderUsers,
  },
  { path: RouteNames.SETTINGS, exact: false, component: Settings },
]
