import { ModalType } from '../../../modals/modalsEnum'
import { ModalActionEnum, SetModalHideAction, SetModalShowAction } from './types'

export const ModalActionCreators = {
  showModal: (modalName: string | ModalType, modalData: object | null): SetModalShowAction => ({
    type: ModalActionEnum.SHOW_MODAL,
    payload: { modalName, modalData },
  }),
  hideModal: (): SetModalHideAction => ({ type: ModalActionEnum.HIDE_MODAL }),
}
