import { IGetPermissions, IUsers } from '../../../models/IUsers'

export enum UsersEnum {
  SET_USERS = 'SET_USERS',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
}

export interface UsersState {
  users: IUsers[]
  permissions: string[]
  totalUsers: number
}

export interface SetUsersAction {
  type: UsersEnum.SET_USERS
  payload: {
    users: IUsers[]
    totalUsers: number
  }
}

export interface SetPermissionsAction {
  type: UsersEnum.SET_PERMISSIONS
  payload: IGetPermissions[]
}

export type UsersAction = SetUsersAction | SetPermissionsAction
