import { MetaTraderActions, MetaTraderUsersEnum, MetaTraderUsersState } from './types'

const initialState: MetaTraderUsersState = {
  metaTraderUsers: [],
  totalItems: 0,
}

export const MetaTraderUsersReducer = (state = initialState, action: MetaTraderActions): MetaTraderUsersState => {
  switch (action.type) {
    case MetaTraderUsersEnum.SET_META_TRADER_USERS:
      return {
        ...state,
        metaTraderUsers: action.payload.items,
        totalItems: action.payload.totalItems,
      }
    case MetaTraderUsersEnum.SEARCH_META_TRADER_USERS:
      return {
        ...state,
        metaTraderUsers: action.payload === 'reject' ? [] : [action.payload],
        totalItems: 1,
      }
    default:
      return state
  }
}
