import React, { useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { RootState } from '../store'

export const ModalLicense: React.FC = React.memo(() => {
  const { hideModal } = useActions()
  const { modalData } = useTypedSelector((state: RootState) => state.modal)
  const handleClose = useCallback(() => hideModal(), [hideModal])

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        <FormattedMessage id="modal.license" />
      </Modal.Header>
      <Modal.Body>
        <span className=" d-flex justify-content-center align-items-center mb-2">© 2022 - Tools For Brokers</span>
        <div className=" d-flex justify-content-between ">
          <div>
            <span>Web version 1.1.0.0</span>
            <br />
          </div>
          <div>
            <span>Web API version {modalData.version}</span>
            <br />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          <FormattedMessage id="ok" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
})
