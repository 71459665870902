import React from 'react'

export interface IRoute {
  path: string
  component: React.ComponentType
  exact?: boolean
}

export enum RouteNames {
  PERIODIC_REPORTS = '/periodic-reports/:page',
  FINANCIAL_INSTRUMENTS = '/financial-instruments',
  META_TREADER_USERS = '/meta-trader-users',
  SETTINGS = '/settings/:page',
  SETTINGS_SERVERS = '/settings/servers',
  SETTINGS_USERS = '/settings/users',
  PERIODIC_REPORTS_SCHEDULED_TASK = '/periodic-reports/scheduled-tasks',
  PERIODIC_REPORTS_COMPLETED_TASK = '/periodic-reports/completed-reports',
}
