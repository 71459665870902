import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { useActions } from '../hooks/useActions'
import { useFormValidation } from '../hooks/useFormValidation'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { buildControlsExtTwoPerLine, textInput, timeInput } from '../inputs/controls'
import { RootState } from '../store'

export const ModalMetaTraderUsers: React.FC = () => {
  const { hideModal } = useActions()
  const { modalData } = useTypedSelector((state: RootState) => state.modal)
  const [inputState, setInputState, touched, setTouched, errors] = useFormValidation(
    {
      ...modalData.item,
      customId: modalData.item.customId ? modalData.item.customId : '',
      dateOfBirth: modalData.item.dateOfBirth ? new Date(modalData.item.dateOfBirth) : moment(new Date()).startOf('day').toDate(),
    },
    {},
  )

  const handleYes = () => {
    modalData.onYes(inputState)
    hideModal()
  }

  const handleNo = () => {
    hideModal()
  }

  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />

  const inputs = buildControlsExtTwoPerLine(
    [timeInput('dateOfBirth', { maxDate: new Date(), flagFormat: true }), textInput('customId').holder('ID')],
    inputState,
    setInputState,
    'meta-trader-users.table',
    touched,
    setTouched,
    errors,
  )

  return (
    <Modal show={true} onHide={handleNo}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <div id="custom-padding">
        <Modal.Body>{inputs}</Modal.Body>
      </div>
      <Modal.Footer>
        <Button onClick={handleNo}>
          <FormattedMessage id="CanselMetaTraderUsers" />
        </Button>
        <Button onClick={handleYes} className="primary-custom">
          <FormattedMessage id="ModifyMetaTraderUsers" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
