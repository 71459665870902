import { FinancialEnum, FinancialState, FinancialSymbolsAction } from './types'

const initialState: FinancialState = {
  financialSymbol: [],
  totalItems: 0,
}

export const FinancialReducer = (state = initialState, action: FinancialSymbolsAction): FinancialState => {
  switch (action.type) {
    case FinancialEnum.SET_FINANCIAL_SYMBOLS:
      return {
        ...state,
        financialSymbol: action.payload.items,
        totalItems: action.payload.totalItems,
      }
    default:
      return state
  }
}
