import React from 'react'
import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'

const bottomRightToastPosition = { position: toast.POSITION.BOTTOM_RIGHT }

export const throwSuccessMessage = (message: string): void => {
  toast.success(<FormattedMessage id={message} />, bottomRightToastPosition)
}

export const throwErrorMessage = (message: string): void => {
  toast.error(<FormattedMessage id={`${message}`} />, bottomRightToastPosition)
}
