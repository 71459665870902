import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const saveJwt = (jwt: string) => {
  cookies.set('jwt_bbi', jwt, { path: '/', maxAge: 10 * 6 })
}

export const getJwt = (): string | null => {
  return cookies.get('jwt_bbi')
}
export const removeJwt = () => {
  cookies.remove('jwt_bbi')
}

export const saveRefreshToken = (refreshToken: string) => {
  cookies.set('refreshToken_bbi', refreshToken, { path: '/', maxAge: 86400 })
}
export const getRefreshToken = (): string => {
  return cookies.get('refreshToken_bbi')
}
export const removeRefreshToken = () => {
  cookies.remove('refreshToken_bbi')
}

export const saveUser = (user: string) => {
  cookies.set('user_bbi', user, { path: '/', maxAge: 86400 })
}
export const getUser = (): string => {
  return cookies.get('user_bbi')
}
export const removeUser = () => {
  cookies.remove('user_bbi')
}

export const saveUserRole = (user: string[]) => {
  localStorage.setItem('userRole_bbi', JSON.stringify(user))
}

export const saveApiPath = (api: string) => {
  localStorage.setItem('apiPath_bbi', JSON.stringify(api))
}

export const getApiPath = (): string | null => {
  return localStorage.getItem('apiPath_bbi')
}

export const removeApiPath = () => {
  localStorage.removeItem('apiPath_bbi')
}

export const getUserRole = (): string[] => {
  const userRole = localStorage.getItem('userRole_bbi')
  if (userRole === null) {
    return []
  }
  return JSON.parse(userRole) || []
}
export const removeUserRole = () => {
  localStorage.removeItem('userRole_bbi')
}
