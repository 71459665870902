import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import CheckBoxInput from './CheckBoxInput'
import DateTimeInput from './DateTimeInput'
import MultiSelectInput from './MultiSelectInput'
import PasswordInput, { PasswordInputMode } from './PasswordInput'
import SearchableSelectInput from './SearchableSelectInput'
import TextInput from './TextInput'

export function textInput(name: string, isDisabled?: boolean, placeholder?: string, className?: string, tooltip?: string, tooltipFlag?: boolean) {
  return {
    name,
    placeholder,
    type: 'text',
    isDisabled,
    tooltip,
    tooltipFlag,
    errMsg: '',
    className,
    setState: null,

    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
  }
}

export function passwordInput(name: string, mode?: PasswordInputMode, placeholder?: string) {
  return {
    name,
    placeholder,
    type: 'password',
    mode,
    _disabled: false,

    setMode(type: string) {
      this.mode = type === 'add' ? 'setup' : 'change'
      return this
    },

    disabled(predicat: boolean) {
      this._disabled = predicat
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
  }
}

export function mselectInput(name: string, options: any, placeholder?: string) {
  return {
    name,
    type: 'multiselect',
    options,
    setState: null,
    _style: null,
    placeholder,
    isDisabled: false,
    holder(str: string) {
      this.placeholder = str
      return this
    },
    stateSetup(setState: any) {
      this.setState = setState
      return this
    },

    styles(styleObject: any) {
      this._style = styleObject
      return this
    },
  }
}

export function sselectInput(name: string, options: any[], prefix?: string, placeholder?: string) {
  return {
    name,
    type: 'searchable_select',
    placeholder,
    prefix,
    options,
    isDisabled: false,
    holder(str: string) {
      this.placeholder = str
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
  }
}

export function checkboxInput(name: string) {
  return {
    name,
    type: 'checkbox',
    _state: null,
    _setState: null,

    disabled(isDisabled: boolean) {
      if (isDisabled) {
        this.type = 'checkbox-disabled'
      }
      return this
    },
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },

    state(stateObject: any) {
      this._state = stateObject
      return this
    },

    setState(setStateCallback: any) {
      this._setState = setStateCallback
      return this
    },
  }
}

export function timeInput(name: string, params?: any) {
  return {
    name,
    type: 'time',
    isDisabled: false,
    params: params ? params : {},
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
  }
}

export function blankInput() {
  return {
    type: 'blank',
  }
}

export function buildControlsExtTwoPerLine(
  controlDescriptors: any[],
  state: any,
  setState: any,
  translatePrefix: string,
  touched?: any,
  setTouched?: any,
  errors?: any,
  className?: string,
  flag?: boolean,
) {
  return (
    <div className="row">
      {buildControlsExt(
        controlDescriptors.filter((descr: any) => descr),
        state,
        setState,
        translatePrefix,
        flag,
        touched,
        setTouched,
        errors,
      )}
    </div>
  )
}

export function buildControlsExt(
  controlDescriptors: any[],
  state: any,
  setState: any,
  translatePrefix: string,
  twoPerLine?: boolean,
  touched?: any,
  setTouched?: any,
  errors?: any,
) {
  // eslint-disable-next-line array-callback-return
  return controlDescriptors.map((control: any) => {
    switch (control.type) {
      case 'blank':
        return <div key={`${uuidv4()}`} style={{ height: 25 }} className={twoPerLine ? 'col-12' : 'col-12'} />
      case 'text':
        return (
          <TextInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            placeholder={control.placeholder}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            isDisabled={control.isDisabled}
            className={control.className ? 'col-12' : 'col-6'}
            errorText={control.errMsg}
            tooltipFlag={control.tooltipFlag}
            tooltip={control.tooltip}
          />
        )
      case 'searchable_select':
        return (
          <SearchableSelectInput
            state={control.state ? control.state : state}
            setState={control.setState ? control.setState : setState}
            options={control.options}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={'col-6'}
            isDisabled={control.isDisabled}
            prefix={control.prefix}
            placeholder={control.placeholder}
          />
        )
      case 'checkbox':
        return (
          <CheckBoxInput
            state={control._state ?? state}
            setState={control._setState ?? setState}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={twoPerLine ? 'col-6' : 'col-12'}
          />
        )
      case 'checkbox-disabled':
        return (
          <CheckBoxInput
            state={state}
            setState={setState}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={twoPerLine ? 'col-6' : ''}
            isDisabled={true}
          />
        )
      case 'password':
        return (
          <PasswordInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            isDisabled={control._disabled}
            className={'col-6'}
            mode={control.mode}
            placeholder={control.placeholder}
          />
        )
      case 'multiselect':
        return (
          <MultiSelectInput
            state={state}
            setState={control.setState ?? setState}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            label={`${translatePrefix}.${control.name}`}
            options={control.options}
            key={control.name}
            className={'col-12'}
            style={control._style}
            placeholder={control.placeholder}
          />
        )
      case 'time':
        return (
          <DateTimeInput
            state={state}
            isDisabled={control.isDisabled}
            setState={setState}
            valueKey={control.name}
            messageKey={`${translatePrefix}.${control.name}`}
            schema={{}}
            showTimeSelect={control.params.showTimeSelectOnly}
            showTimeSelectOnly={control.params.showTimeSelectOnly}
            timeIntervals={control.params.timeIntervals}
            key={control.name}
            minDate={control.params.minDate}
            maxDate={control.params.maxDate}
            flagFormat={control.params.flagFormat}
            className={twoPerLine ? 'col-12' : 'col-6'}
          />
        )
      default:
        break
    }
  })
}
