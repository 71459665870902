import { faCogs, faFileLines, faMagnifyingGlassDollar, faUsers } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconItem } from 't4b-core-frontend/lib'
import { User } from '../../utils/authRole'

interface IAppSideBarItem {
  translateKey: string
  tooltipKey: JSX.Element
  isExternal: boolean
  link: string
  icon: IconItem
}

export const AppSideBarItem = (): IAppSideBarItem[] => {
  const user = new User()
  return [
    {
      translateKey: 'sidebar.financial-instruments',
      tooltipKey: <FormattedMessage id="sidebar.financial-instruments" />,
      isExternal: false,
      link: user.genLink('/financial-instruments'),
      icon: new IconItem('48px', faMagnifyingGlassDollar, '1x', '10px', '#6EC0BA'),
    },
    {
      translateKey: 'sidebar.meta-trader-users',
      tooltipKey: <FormattedMessage id="sidebar.meta-trader-users" />,
      isExternal: false,
      link: user.genLink('/meta-trader-users'),
      icon: new IconItem('48px', faUsers, '1x', '10px', '#6EC0BA'),
    },
    {
      translateKey: 'sidebar.settings',
      tooltipKey: <FormattedMessage id="sidebar.settings" />,
      isExternal: false,
      link: user.genLink('/settings'),
      icon: new IconItem('48px', faCogs, '1x', '10px', '#6EC0BA'),
    },
  ].filter((item: IAppSideBarItem) => user.hasAccess(item.link))
}
