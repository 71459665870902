import { AuthActionCreators } from './auth/action-creators'
import { ModalActionCreators } from './modals/action-ceators'
import { RightBarActionCreators } from './rightBar/action-creators'
import { ServersActionCreators } from './servers/action-creators'
import { UsersActionCreators } from './users/action-creators'
import { FinancialActionCreators } from './financial/action-creaters'
import { MetaTraderUsersActionCreators } from './metaTraderUsers/action-creaters'

export const allActionCreators = {
  ...AuthActionCreators,
  ...ModalActionCreators,
  ...RightBarActionCreators,
  ...ServersActionCreators,
  ...UsersActionCreators,
  ...FinancialActionCreators,
  ...MetaTraderUsersActionCreators,
}
