import axios, { AxiosResponse } from 'axios'
import { ISymbol } from '../models/ISymbol'
import { api, buildHeaders } from './apiUtils'

export class FinancialService {
  static async getFinancialSymbols(
    mask?: string | undefined,
    pageSize?: string,
    PreviouslyLoaded?: string,
    ServerId?: string,
  ): Promise<AxiosResponse<{ items: ISymbol[]; totalItems: number }>> {
    const url = new URL(`${api()}/Symbols`)
    if (mask) {
      url.searchParams.set('Mask', mask)
    }
    url.searchParams.set('PageSize', pageSize ?? '250')
    url.searchParams.set('PreviouslyLoaded', PreviouslyLoaded ?? '0')
    url.searchParams.set('ServerId', ServerId ?? '0')
    return axios.get(`${url}`, {
      headers: buildHeaders(),
    })
  }

  static async editFinancialSymbol(symbol: ISymbol): Promise<AxiosResponse<ISymbol>> {
    return axios.put(`${api()}/Symbols`, symbol, {
      headers: buildHeaders(),
    })
  }

  static async resetFinancialSymbol(id: number): Promise<AxiosResponse<ISymbol>> {
    return axios.put(
      `${api()}/Symbols/ResetManuallyTyped/${id}`,
      {},
      {
        headers: buildHeaders(),
      },
    )
  }
}
