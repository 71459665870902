import { getUserRole } from '../store/reducers/auth/authUtils'

enum UserRoles {
  ReportsRead = 'Reports.Read',
  SymbolsRead = 'Symbols.Read',
  MtServersRead = 'MtServers.Read',
  IdentityRead = 'Identity.Read',
  UsersRead = 'Users.Read',
}

const rights: { [key: string]: UserRoles } = {
  '/periodic-reports/scheduled-tasks': UserRoles.ReportsRead,
  '/periodic-reports/completed-reports': UserRoles.ReportsRead,
  '/financial-instruments': UserRoles.SymbolsRead,
  '/meta-trader-users': UserRoles.UsersRead,
  '/settings/servers': UserRoles.MtServersRead,
  '/settings/users': UserRoles.IdentityRead,
}

export class User {
  public rights: string[]

  constructor() {
    this.rights = getUserRole()
  }

  hasAccess(path: string): boolean {
    const isGroupedPath = (path: string) => path.includes(':')
    const requiredRight: string | null = rights[path]

    if (isGroupedPath(path)) {
      return true
    }
    return this.rights.includes(requiredRight)
  }

  genLink(path: string): string {
    const link = Object.keys(rights)
      .filter((right: string) => right.startsWith(path))
      .find((url: string) => this.hasAccess(url))
    return link || '/not-enough-privilege'
  }
}
