import React, { useEffect } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface ITextInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  flag?: boolean
  tooltipFlag?: boolean
  tooltip?: string

  setState(state: any): any

  setTouched?(touched: any): any
}

const TextInput: React.FC<ITextInput> = props => {
  const { state, setState, touched, setTouched, errors, errorText, name, className, placeholder, label, isDisabled, flag, tooltipFlag, tooltip } = props

  let validThreshold = !(name === 'Threshold' && +state.Threshold >= 0 && !String(state.Threshold).includes(',') && !String(state.Threshold).includes('e'))

  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const renderTooltip = (props?: any, tooltip?: string) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  return (
    <>
      {tooltipFlag ? (
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
          <Form.Group className={className}>
            {label ? (
              <>
                <Form.Label>
                  <FormattedMessage id={label} tagName="span" />
                </Form.Label>
                <span>:</span>
              </>
            ) : null}
            <Form.Control
              type="text"
              placeholder={placeholder}
              value={state[name]}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={Boolean(isDisabled)}
              isInvalid={
                (flag ? validThreshold : errors) && (flag ? true : touched) && (flag ? true : touched[name]) && errors && (flag ? `${name}s` : errors[name])
              }
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
            </Form.Control.Feedback>
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group className={className}>
          {label ? (
            <>
              <Form.Label>
                <FormattedMessage id={label} tagName="span" />
              </Form.Label>
              <span>:</span>
            </>
          ) : null}
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={state[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={Boolean(isDisabled)}
            isInvalid={
              (flag ? validThreshold : errors) && (flag ? true : touched) && (flag ? true : touched[name]) && errors && (flag ? `${name}s` : errors[name])
            }
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </>
  )
}

export default React.memo(TextInput)
