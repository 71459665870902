import { Languages } from 't4b-core-frontend/lib/entity/languages'
import { ILogo } from 't4b-core-frontend/lib/entity/logo'
import MESSAGES_EN from '../../i18n/en.json'
import mainLogo from '../../img/logo.png'

export const appLogo: ILogo = {
  src: mainLogo,
  height: 48,
  width: 215,
  className: '',
  alt: 'logo',
  href: '/periodic-reports/scheduled-tasks',
}

export const APP_LANGS: Languages = {
  en: 'English',
}

export const messages: any = {
  en: MESSAGES_EN,
}
