import { IMetaTraderUsers } from '../../../models/IMetaTraderUsers'

export enum MetaTraderUsersEnum {
  SET_META_TRADER_USERS = 'SET_META_TRADER_USERS',
  SEARCH_META_TRADER_USERS = 'SEARCH_META_TRADER_USERS',
}

export interface MetaTraderUsersState {
  metaTraderUsers: IMetaTraderUsers[]
  totalItems: number
}

export interface FinancialSymbolsAction {
  type: MetaTraderUsersEnum.SET_META_TRADER_USERS
  payload: {
    items: IMetaTraderUsers[]
    totalItems: number
  }
}

export interface MetaTraderUsersStateSearch {
  type: MetaTraderUsersEnum.SEARCH_META_TRADER_USERS
  payload: IMetaTraderUsers | 'reject'
}

export type MetaTraderActions = FinancialSymbolsAction | MetaTraderUsersStateSearch
