import { UsersService } from '../../../api/UsersService'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import { AppDispatch } from '../../index'
import { UsersEnum } from './types'
import { IUsers } from '../../../models/IUsers'

export const UsersActionCreators = {
  getUsers: (PageSize?: string, PreviouslyLoaded?: string, setLoading?: (cb: boolean) => void) => async (dispatch: AppDispatch) => {
    try {
      if (!setLoading) {
        const { data } = await UsersService.getUsers(PageSize, PreviouslyLoaded)
        dispatch({ type: UsersEnum.SET_USERS, payload: data })
      } else {
        setLoading(true)
        const { data } = await UsersService.getUsers(PageSize, PreviouslyLoaded)
        dispatch({ type: UsersEnum.SET_USERS, payload: data })
        setLoading(false)
      }
    } catch (error: unknown) {}
  },
  addUser: (user: IUsers) => async () => {
    try {
      await UsersService.addUser(user)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  changePermissions: (user: IUsers) => async () => {
    try {
      await UsersService.changePermissions(user)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  changePassword: (user: { [key: string]: string }) => async () => {
    try {
      await UsersService.changePassword(user)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  deleteUser: (login: string) => async () => {
    try {
      await UsersService.deleteUser(login)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  getPermissions: () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await UsersService.getPermissions()
      dispatch({ type: UsersEnum.SET_PERMISSIONS, payload: data })
    } catch (error: unknown) {}
  },
}
