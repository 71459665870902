import { IMultiSelectItem } from '../models/IMultiSelectItem'

export function buildSelectOption(item: string, defaultValue?: string): IMultiSelectItem {
  return { value: item ?? defaultValue, label: item ?? defaultValue }
}

export function optionToString(item: IMultiSelectItem): string {
  if (typeof item === 'object') {
    return item.value
  }
  return ''
}

export function buildMultiselectOptionsFromArray(arr: string[]): IMultiSelectItem[] | [] | string[] {
  if (Array.isArray(arr)) {
    return arr.map((item: string) => {
      if (item === 'ForexOrCurrencies') {
        return { value: item, label: 'Forex or currencies' }
      }
      if (item === 'StockOrEquity') {
        return { value: item, label: 'Stock or equity' }
      }

      return { value: item, label: item }
    })
  }
  return []
}
