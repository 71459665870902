import React from 'react'

const Loader: React.FC = (): JSX.Element => {
  return (
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default React.memo(Loader)
