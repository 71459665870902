import axios, { AxiosResponse } from 'axios'
import { IServersItemId, ITimezone } from '../models/IServers'
import { api, buildHeaders } from './apiUtils'

export class ServersService {
  static async getServers(PageSize?: string, PreviouslyLoaded?: string): Promise<AxiosResponse<any>> {
    const url = new URL(`${api()}/MtServers`)
    url.searchParams.set('PageSize', PageSize ?? '250')
    url.searchParams.set('PreviouslyLoaded', PreviouslyLoaded ?? '0')
    return axios.get(`${url}`, {
      headers: buildHeaders(),
    })
  }

  static async getServersItemId(id: string): Promise<AxiosResponse<IServersItemId>> {
    return axios.get(`${api()}/MtServers/${id}`, {
      headers: buildHeaders(),
    })
  }

  static async modifyServersItemId(item: IServersItemId): Promise<AxiosResponse<IServersItemId>> {
    return axios.put(
      `${api()}/MtServers`,
      { ...item },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async addServersItemId(item: IServersItemId): Promise<AxiosResponse<IServersItemId>> {
    return axios.post(
      `${api()}/MtServers`,
      { ...item },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async reloadServersItemId(id: string, date: Date): Promise<AxiosResponse<any>> {
    return axios.post(
      `${api()}/MtServers/reload`,
      {
        serverId: id,
        fromDateTimeUtc: date,
      },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async getTimezones(): Promise<AxiosResponse<ITimezone[]>> {
    return axios.get(`${api()}/Timezones`, {
      headers: buildHeaders(),
    })
  }
}
