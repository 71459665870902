import { ISymbol } from '../../../models/ISymbol'

export enum FinancialEnum {
  SET_FINANCIAL_SYMBOLS = 'SET_FINANCIAL_SYMBOLS',
}

export interface FinancialState {
  financialSymbol: ISymbol[]
  totalItems: number
}

export interface FinancialSymbolsAction {
  type: FinancialEnum.SET_FINANCIAL_SYMBOLS
  payload: {
    items: ISymbol[]
    totalItems: number
  }
}
