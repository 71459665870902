import { UsersAction, UsersEnum, UsersState } from './types'

const initialState: UsersState = {
  users: [],
  permissions: [],
  totalUsers: 0,
}

export const UsersReducer = (state = initialState, action: UsersAction): UsersState => {
  switch (action.type) {
    case UsersEnum.SET_USERS:
      return {
        ...state,
        users: action.payload.users,
        totalUsers: action.payload.totalUsers,
      }
    case UsersEnum.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.map(({ name }: { name: string }) => name),
      }
    default:
      return state
  }
}
