import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { AppAccordion } from 't4b-core-frontend/lib'
import { Server } from '../entity/Servers'
import { useActions } from '../hooks/useActions'
import { useFormValidation } from '../hooks/useFormValidation'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { buildControlsExtTwoPerLine, passwordInput, sselectInput, textInput } from '../inputs/controls'
import { ITimezone } from '../models/IServers'
import { buildMultiselectOptionsFromArray, optionToString } from '../utils/buildSelectOptions'

interface IProps {
  data: {
    type: string
    setLoading: (cb: boolean) => void
  }
}

export const ServerSettings: FC<IProps> = ({ data: { type, setLoading } }) => {
  const { modifyMtServersItemId, hideRightBar, getMtServers, addMtServersItemId } = useActions()
  const { ServersItemId, Timezone } = useTypedSelector(state => state.servers)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(type === 'add' ? new Server({}) : new Server(ServersItemId), {})

  const buildTimezone = Timezone.map((item: ITimezone) => item.name)
  const inputsServersSettings = buildControlsExtTwoPerLine(
    [
      textInput('serverName', false, 'Unique name'),
      sselectInput('serverType', buildMultiselectOptionsFromArray(['None', 'MT4', 'MT5'])).holder('Server type'),
      textInput('reportServerIp', false, '127.0.0.1:5432'),
      textInput('reportServerUser', false, 'Database user'),
      passwordInput('reportServerPassword', type === 'add' ? 'setup' : 'change').holder('Password'),
      textInput('reportServerDbName', false, 'Database name'),
      sselectInput('reportServerDbProvider', buildMultiselectOptionsFromArray(['None', 'MySql', 'Postgres'])).holder('Server DB provider'),
      textInput('managerConnectionIp', false, '127.0.0.1:443'),
    ],
    inputState,
    setInputState,
    'ServerSettings',
    touched,
    setTouched,
    errors,
  )

  const inputsServersSettingsManagerConnection = buildControlsExtTwoPerLine(
    [textInput('managerConnectionUser', false, 'Login'), passwordInput('managerConnectionPassword', type === 'add' ? 'setup' : 'change').holder('Password')],
    inputState,
    setInputState,
    'ServerSettings',
    touched,
    setTouched,
    errors,
  )

  const inputsServersSettingsTp = buildControlsExtTwoPerLine(
    [
      textInput('tpUri', false, 'http://127.0.0.1:81'),
      textInput('tpUser', false, 'Login'),
      passwordInput('tpPassword', type === 'add' ? 'setup' : 'change').holder('Password'),
      textInput('tpPlatformName', false, 'Platform name'),
    ],
    inputState,
    setInputState,
    'ServerSettings',
    touched,
    setTouched,
    errors,
  )

  const inputsServersSettingsOther = buildControlsExtTwoPerLine(
    [
      sselectInput('serverStatus', buildMultiselectOptionsFromArray(['None', 'Synchronizing', 'Suspended', 'Deleting'])).holder('Server status'),
      sselectInput('timezoneName', buildMultiselectOptionsFromArray(buildTimezone)).holder('Timezone'),
      textInput('includedGroups', false, 'Mask', '', 'Mask', true),
    ],
    inputState,
    setInputState,
    'ServerSettings',
    touched,
    setTouched,
    errors,
  )

  const handleSave = async () => {
    const findTimezoneId = Timezone.find((item: ITimezone) => item.name === optionToString(inputState.timezoneName))
    const body = {
      ...inputState,
      reportServerDbProvider: optionToString(inputState.reportServerDbProvider),
      serverType: optionToString(inputState.serverType),
      serverStatus: optionToString(inputState.serverStatus),
      timezoneName: optionToString(inputState.timezoneName),
      timezoneId: findTimezoneId?.id,
      includedGroups: Array.isArray(inputState.includedGroups) ? inputState.includedGroups : inputState.includedGroups?.split(','),
    }
    if (type === 'modify') {
      await modifyMtServersItemId(body)
    } else {
      await addMtServersItemId(body)
    }

    await getMtServers('250', '0', setLoading)
    await hideRightBar()
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`ServerSettings.title.${type}`} />,
          item: [
            <div key="Servers settings" className="mt-3">
              <fieldset className="border-custom">
                <legend className="text-center">
                  <span>Server settings</span>
                </legend>
                {inputsServersSettings}
              </fieldset>
            </div>,
            <div key="Manager connection" className="mt-5">
              <fieldset className="border-custom">
                <legend className="text-center">
                  <span>Manager connection</span>
                </legend>
                {inputsServersSettingsManagerConnection}
              </fieldset>
            </div>,
            <div key="Settings TP" className="mt-5">
              <fieldset className="border-custom">
                <legend className="text-center">
                  <span>TP connection</span>
                </legend>
                {inputsServersSettingsTp}
              </fieldset>
            </div>,
            <div key="Other" className="mt-5">
              <fieldset className="border-custom">
                <legend className="text-center">
                  <span>Other</span>
                </legend>
                {inputsServersSettingsOther}
              </fieldset>
            </div>,
          ],
        }}
        isHidden={false}
        render={inputState.length}
      />
      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}
