import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { RootState } from '../store'

export const ModalGeneral: React.FC = () => {
  const { hideModal } = useActions()
  const { modalData } = useTypedSelector((state: RootState) => state.modal)

  const handleYes = () => {
    modalData.onYes()
    hideModal()
  }

  const handleNo = () => {
    hideModal()
  }

  const headerText = typeof modalData.headerText === 'function' ? modalData.headerText() : <FormattedMessage id={modalData.headerText} />
  const bodyText = typeof modalData.bodyText === 'function' ? modalData.bodyText() : <FormattedMessage id={modalData.bodyText} />

  return (
    <Modal show={true} onHide={handleNo}>
      <Modal.Header closeButton={true} className="color-dark font-500">
        {headerText}
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleYes}>
          <FormattedMessage id="yes" />
        </Button>
        <Button onClick={handleNo}>
          <FormattedMessage id="no" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
