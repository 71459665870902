import { IServersItemId } from '../../../models/IServers'
import { ServersAction, ServersMtActionEnum, ServersState } from './types'

const initialState: ServersState = {
  ServersMt: [],
  ServersItemId: {} as IServersItemId,
  Timezone: [],
  totalItems: 0,
}

export const ServersMtReducer = (state = initialState, action: ServersAction): ServersState => {
  switch (action.type) {
    case ServersMtActionEnum.SET_SERVERSMT:
      return {
        ...state,
        ServersMt: action.payload.items,
        totalItems: action.payload.totalItems,
      }
    case ServersMtActionEnum.SET_SERVERSITEMID:
      return { ...state, ServersItemId: action.payload }
    case ServersMtActionEnum.SET_TIMEZONE:
      return { ...state, Timezone: action.payload }
    default:
      return state
  }
}
