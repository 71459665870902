import React from 'react'
import { FormattedMessage } from 'react-intl'

export const NotEnoughPrivilege: React.FC = () => {
  return (
    <>
      <h2>
        <FormattedMessage id="not-enough-privilege" />
      </h2>
    </>
  )
}
