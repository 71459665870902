import React, { FC, memo, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { AppAccordion } from 't4b-core-frontend/lib'
import { Symbol } from '../entity/Symbol'
import { useActions } from '../hooks/useActions'
import { useFormValidation } from '../hooks/useFormValidation'
import { buildControlsExtTwoPerLine, checkboxInput, sselectInput, textInput } from '../inputs/controls'
import { buildMultiselectOptionsFromArray, optionToString } from '../utils/buildSelectOptions'
import { ISymbol } from '../models/ISymbol'

interface IProps {
  data: {
    type: string
    serverId: number
    setLoading: (cb: boolean) => void
    item: ISymbol
  }
}

export const FinancialInstrumentRightBar: FC<IProps> = memo(({ data: { item, type, serverId, setLoading } }) => {
  const { editFinancialSymbol, hideRightBar, getFinancialSymbols } = useActions()
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new Symbol(item), Symbol.schema)

  const [render, setRender] = useState(false)

  useEffect(() => {
    if (type === 'modify' && render) {
      setInputState((prev: ISymbol) => {
        return {
          ...prev,
          isManuallyTyped: true,
        }
      })
    }
    setRender(true)
  }, [inputState.name, inputState.assetType, inputState.executionType, inputState.derivativeType])

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('name'),
      sselectInput(
        'assetType',
        buildMultiselectOptionsFromArray(['Commodities', 'ForexOrCurrencies', 'Crypto', 'Index', 'StockOrEquity', 'Bond']),
        'assetType',
      ),
      sselectInput('executionType', buildMultiselectOptionsFromArray(['CFD', 'Exchange'])),
      sselectInput('derivativeType', buildMultiselectOptionsFromArray(['None', 'Futures', 'Options'])),
      checkboxInput('isManuallyTyped'),
    ],
    inputState,
    setInputState,
    'FinancialInstrumentRightBar',
    touched,
    setTouched,
    errors,
  )

  const handleSave = async () => {
    if (type === 'modify') {
      const body = {
        ...inputState,
        id: item.id,
        assetType: optionToString(inputState.assetType),
        executionType: optionToString(inputState.executionType),
        derivativeType: optionToString(inputState.derivativeType),
      }
      await editFinancialSymbol(body)
    }
    await getFinancialSymbols('', '250', '0', `${serverId}`, setLoading)
    await hideRightBar()
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id="FinancialInstrumentRightBar.title" />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
})
