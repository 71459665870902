import { IMultiSelectItem } from '../models/IMultiSelectItem'
import { buildSelectOption } from '../utils/buildSelectOptions'

export class Server {
  serverId: string
  serverName: string
  serverType: IMultiSelectItem
  reportServerIp: string
  reportServerUser: string
  reportServerPassword: string
  reportServerDbName: string
  reportServerDbProvider: IMultiSelectItem
  managerConnectionIp: string
  managerConnectionUser: number
  managerConnectionPassword: string
  tpUri: string
  tpUser: string
  tpPassword: string
  tpPlatformName: string
  serverStatus: IMultiSelectItem
  timezoneName: IMultiSelectItem
  errorCount: number
  includedGroups: string[]
  timezoneId: number

  static schema = {}

  constructor(item?: any) {
    this.timezoneId = item?.timezoneId ?? 1
    this.serverId = item?.serverId ?? ''
    this.serverName = item?.serverName ?? 'Unique name'
    this.serverType = buildSelectOption(item?.serverType ? item?.serverType.toUpperCase() : 'None')
    this.reportServerIp = item?.reportServerIp ?? '127.0.0.1:5432'
    this.reportServerUser = item?.reportServerUser ?? 'Database user'
    this.reportServerPassword = item?.reportServerPassword ?? 'Password'
    this.reportServerDbName = item?.reportServerDbName ?? 'Database name'
    this.reportServerDbProvider = buildSelectOption(item?.reportServerDbProvider ? item?.reportServerDbProvider : 'None')
    this.managerConnectionIp = item?.managerConnectionIp ?? '127.0.0.1:443'
    this.managerConnectionUser = item?.managerConnectionUser ?? 0
    this.managerConnectionPassword = item?.managerConnectionPassword ?? 'Password'
    this.tpUri = item?.tpUri ?? 'http://127.0.0.1:81'
    this.tpUser = item?.tpUser ?? 'Login'
    this.tpPassword = item?.tpPassword ?? 'Password'
    this.tpPlatformName = item?.tpPlatformName ?? 'Platform name'
    this.serverStatus = buildSelectOption(item?.serverStatus ? item?.serverStatus : 'None')
    this.timezoneName = buildSelectOption(item?.timezoneName ? item?.timezoneName : '(UTC) Coordinated Universal Time')
    this.errorCount = item?.errorCount ?? 0
    this.includedGroups = item?.includedGroups ?? '*'
  }
}
