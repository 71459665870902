import { RightBarActionEnum, SetRightBarHideAction, SetRightBarShowAction } from './types'

export const RightBarActionCreators = {
  showRightBar: (rightBarName: string, rightBarData: object | null): SetRightBarShowAction => ({
    type: RightBarActionEnum.SHOW_RIGHTBAR,
    payload: { rightBarName, rightBarData },
  }),
  hideRightBar: (): SetRightBarHideAction => ({
    type: RightBarActionEnum.HIDE_RIGHTBAR,
  }),
}
