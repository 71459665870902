import { MetaTraderUsersService } from '../../../api/MetaTraderUsersService'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import { AppDispatch } from '../../index'
import { MetaTraderUsersEnum } from './types'

export const MetaTraderUsersActionCreators = {
  getMetaTraderUsers:
    (pageSize?: string, PreviouslyLoaded?: string, ServerId?: string, setLoading?: (cb: boolean) => void) => async (dispatch: AppDispatch) => {
      try {
        if (setLoading) {
          setLoading(true)
          const { data } = await MetaTraderUsersService.getMetaTraderUsers(pageSize, PreviouslyLoaded, ServerId)
          dispatch({ type: MetaTraderUsersEnum.SET_META_TRADER_USERS, payload: data })
          setLoading(false)
        } else {
          const { data } = await MetaTraderUsersService.getMetaTraderUsers(pageSize, PreviouslyLoaded, ServerId)
          dispatch({ type: MetaTraderUsersEnum.SET_META_TRADER_USERS, payload: data })
        }
      } catch (error: unknown) {}
    },

  editMetaTraderUsers: (login: number, serverId: number, customId: string, dateOfBirth: Date | string) => async () => {
    try {
      await MetaTraderUsersService.editMetaTraderUsers(login, serverId, customId, dateOfBirth)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },

  searchMetaTraderUsers: (login: string, serverId: string, setLoading?: (cb: boolean) => void) => async (dispatch: AppDispatch) => {
    try {
      if (setLoading) {
        setLoading(true)
        const { data } = await MetaTraderUsersService.searchMetaTraderUsers(login, serverId)
        dispatch({ type: MetaTraderUsersEnum.SEARCH_META_TRADER_USERS, payload: data })
        setLoading(false)
      } else {
        const { data } = await MetaTraderUsersService.searchMetaTraderUsers(login, serverId)
        dispatch({ type: MetaTraderUsersEnum.SEARCH_META_TRADER_USERS, payload: data })
      }
    } catch (error: unknown) {
      if (setLoading) {
        dispatch({ type: MetaTraderUsersEnum.SEARCH_META_TRADER_USERS, payload: 'reject' })
        setLoading(false)
      }
    }
  },
}
