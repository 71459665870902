import { FinancialService } from '../../../api/FinancialService'
import { ISymbol } from '../../../models/ISymbol'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import { AppDispatch } from '../../index'
import { FinancialEnum } from './types'

export const FinancialActionCreators = {
  getFinancialSymbols:
    (mask?: string, pageSize?: string, PreviouslyLoaded?: string, ServerId?: string, setLoading?: (cb: boolean) => void) => async (dispatch: AppDispatch) => {
      try {
        if (setLoading) {
          setLoading(true)
          const { data } = await FinancialService.getFinancialSymbols(mask, pageSize, PreviouslyLoaded, ServerId)
          dispatch({ type: FinancialEnum.SET_FINANCIAL_SYMBOLS, payload: data })
          setLoading(false)
        } else {
          const { data } = await FinancialService.getFinancialSymbols(mask, pageSize, PreviouslyLoaded, ServerId)
          dispatch({ type: FinancialEnum.SET_FINANCIAL_SYMBOLS, payload: data })
        }
      } catch (error: unknown) {}
    },
  editFinancialSymbol: (symbol: ISymbol) => async () => {
    try {
      await FinancialService.editFinancialSymbol(symbol)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  resetFinancialSymbol: (id: number) => async () => {
    try {
      await FinancialService.resetFinancialSymbol(id)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
}
