import moment from 'moment'

export function localAsUTC(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}

export const asTime = (value: string | number, format = 'MMMM Do YYYY, HH:mm:ss') => {
  return value === 0
    ? ''
    : moment(value)
        .locale(localStorage.getItem('lang') || 'en-gb')
        .format(format)
}

export const asTimeNotHours = (value: string | number, format = 'MMMM Do YYYY') => {
  return value === 0 || typeof value === 'object'
    ? ''
    : moment(value)
        .locale(localStorage.getItem('lang') || 'en-gb')
        .format(format)
}
