import React from 'react'
import { Redirect, Route } from 'react-router'
import { IPrivateRoute } from '../models/IPrivateRoute'
import { User } from '../utils/authRole'

export const PrivateRoute = ({ component, ...rest }: IPrivateRoute): JSX.Element => {
  const user = new User()
  const toRender = () => {
    if (rest.isAuthenticated) {
      if (user.hasAccess(rest.path)) {
        return component
      }
      return <Redirect to={{ pathname: '/not-enough-privilege' }} />
    }

    return <Redirect to={{ pathname: '/login' }} />
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={toRender} />
}
