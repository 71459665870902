import en from 'date-fns/locale/en-US'
import ja from 'date-fns/locale/ja'
import * as React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'

registerLocale('en', en)
registerLocale('ja', ja)

interface IInputProps {
  state: any
  valueKey: string
  messageKey: string
  schema: any
  disabled?: boolean
  className?: string
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  timeIntervals?: number
  minDate?: Date
  maxDate?: Date
  isDisabled?: boolean
  flagFormat?: boolean

  setState(newState: any): void
}
const DateTimeInput: React.FC<IInputProps> = props => {
  const { state, valueKey, setState, messageKey, className, showTimeSelectOnly, timeIntervals, minDate, isDisabled, maxDate, flagFormat } = props

  const intl = useIntl()
  const onChange = (date: Date) => setState({ ...state, [valueKey]: date })

  const dpClassName = `form-control flex-fill ${state[valueKey] ? '' : ''}` //form-control is-invalid

  return (
    <Form.Group className={'d-flex flex-column ' + className ? className : ''}>
      <Form.Label>
        <FormattedMessage id={messageKey} tagName="span" />
        <span>:</span>
      </Form.Label>
      <DatePicker
        locale={intl.locale}
        disabled={!!isDisabled}
        className={dpClassName}
        selected={state[valueKey] as Date}
        onChange={onChange}
        timeCaption="time"
        dateFormat={showTimeSelectOnly ? 'HH:mm:ss' : flagFormat ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm:ss'}
        timeFormat="HH:mm:ss"
        showTimeSelect
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        minDate={minDate ? new Date() : undefined}
        maxDate={maxDate ? new Date() : undefined}
      />
    </Form.Group>
  )
}

export default React.memo(DateTimeInput)
