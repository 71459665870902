import { RightBarAction, RightBarActionEnum, RightBarState } from './types'

const initialState: RightBarState = {
  rightBarHidden: true,
  rightBarName: '',
  rightBarData: {},
}

export const rightBarReducer = (state = initialState, action: RightBarAction): RightBarState => {
  switch (action.type) {
    case RightBarActionEnum.SHOW_RIGHTBAR:
      return {
        ...state,
        rightBarName: action.payload.rightBarName,
        rightBarData: action.payload.rightBarData,
        rightBarHidden: false,
      }
    case RightBarActionEnum.HIDE_RIGHTBAR:
      return { rightBarName: '', rightBarData: {}, rightBarHidden: true }
    default:
      return state
  }
}
