export enum RightBarActionEnum {
  SHOW_RIGHTBAR = 'SHOW_RIGHTBAR',
  HIDE_RIGHTBAR = 'HIDE_RIGHTBAR',
}

export interface RightBarState {
  rightBarHidden: boolean
  rightBarName: string
  rightBarData: any
}

export interface SetRightBarShowAction {
  type: RightBarActionEnum.SHOW_RIGHTBAR
  payload: any
}

export interface SetRightBarHideAction {
  type: RightBarActionEnum.HIDE_RIGHTBAR
}

export type RightBarAction = SetRightBarHideAction | SetRightBarShowAction
