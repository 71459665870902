import React, { useCallback } from 'react'
import { AppRightBar } from 't4b-core-frontend/lib'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { FinancialInstrumentRightBar } from './FinancialInstrumentRightBar'
import { RightBarType } from './RightBarEnum'
import { ServerSettings } from './ServerSettings'
import { UsersSettings } from './UsersSettings'

export const RightBarContainer: React.FC = () => {
  const { hideRightBar } = useActions()
  const { rightBarHidden, rightBarName, rightBarData } = useTypedSelector(state => state.rightBar)

  let rightBarItem = <div />
  switch (rightBarName) {
    case RightBarType.ServerSettings:
      rightBarItem = <ServerSettings data={rightBarData} />
      break
    case RightBarType.ServerUsers:
      rightBarItem = <UsersSettings data={rightBarData} />
      break
    case RightBarType.FinancialInstrument:
      rightBarItem = <FinancialInstrumentRightBar data={rightBarData} />
      break
    default:
      break
  }

  const handleClick = useCallback(() => hideRightBar(), [hideRightBar])

  return <AppRightBar className="app-rightbar w-800" hidden={rightBarHidden} items={rightBarItem} handleClick={handleClick} />
}
