import { ModalAction, ModalActionEnum, ModalState } from './types'

const initialState: ModalState = {
  modalName: '',
  modalData: {},
}

export const modalReducer = (state = initialState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalActionEnum.SHOW_MODAL:
      return {
        ...state,
        modalName: action.payload.modalName,
        modalData: action.payload.modalData,
      }
    case ModalActionEnum.HIDE_MODAL:
      return { modalName: '', modalData: {} }
    default:
      return state
  }
}
