import { IUsers } from '../models/IUsers'

export class Users {
  login: string
  password?: string
  newPassword?: string
  permissions: string[]

  static schema = {}

  constructor(item?: IUsers) {
    this.login = item?.login ?? ''
    this.password = item?.password ?? ''
    this.newPassword = item?.newPassword ?? ''
    this.permissions = item?.permissions ?? []
  }
}
