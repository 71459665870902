export enum ModalActionEnum {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}

export interface ModalState {
  modalName: string | null
  modalData: any
}

export interface SetModalShowAction {
  type: ModalActionEnum.SHOW_MODAL
  payload: any
}

export interface SetModalHideAction {
  type: ModalActionEnum.HIDE_MODAL
}

export type ModalAction = SetModalHideAction | SetModalShowAction
