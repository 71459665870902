import React, { memo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AppLoginPage } from 't4b-core-frontend'
import { v4 as uuidv4 } from 'uuid'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { NotEnoughPrivilege } from '../pages/NotEnoughPrivelege'
import { privateRoutes } from '../router'
import { User } from '../utils/authRole'
import { AppSideBarItem } from './AppSideBar/AppSideBarItem'
import Loader from './Loader'
import { PrivateRoute } from './PrivateRoute'

export const AppRouter = memo(() => {
  const { login } = useActions()
  const { isAuth } = useTypedSelector(state => state.auth)
  const user = new User()

  const onSignIn = (username: string, password: string) => {
    login(username, password)
  }

  const renderAppLoginPage = () =>
    isAuth ? (
      <Redirect to={AppSideBarItem()[0]?.link} />
    ) : (
      <div>
        <AppLoginPage canBeRestored={false} canBeRegistered={false} signInFunc={onSignIn} />
      </div>
    )

  return (
    <React.Suspense
      fallback={
        <div className="wrapper-loader">
          <Loader />
        </div>
      }
    >
      <Switch>
        {privateRoutes.map(route => (
          <PrivateRoute key={`${uuidv4()}`} component={<route.component />} path={route.path} isAuthenticated={isAuth} />
        ))}

        <Route path="/login" component={renderAppLoginPage} />
        <PrivateRoute exact={true} path="/" isAuthenticated={false} component={renderAppLoginPage} />
        <Route exact={true} path="/settings" render={() => <Redirect to={user.genLink('/settings/servers')} />} />
        <Route exact={true} path="/periodic-reports" render={() => <Redirect to={user.genLink('/periodic-reports/scheduled-tasks')} />} />
        <Route exact={true} path="/not-enough-privilege" component={NotEnoughPrivilege} />
        <Route exact={true} path="*" component={renderAppLoginPage} />
      </Switch>
    </React.Suspense>
  )
})
