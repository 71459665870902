import 'moment/locale/ja'
import 'moment/locale/en-gb'
import React from 'react'
import { useIntl } from 'react-intl'
import Moment from 'react-moment'

export const CurrentTime: React.FC = () => {
  const intl = useIntl()

  return (
    <div key="time-component" className="d-flex flex-column justify-content-center text-muted">
      <Moment interval={1000} tz="utc" format="MMM Do, HH:mm:ss" locale={intl.locale} />
    </div>
  )
}
