import { getApiPath, getJwt } from '../store/reducers/auth/authUtils'

export const api = () => `${getApiPath()?.replace(/['"]+/g, '')}/v1`

export const buildHeaders = () => {
  return {
    'Content-Type': 'application/json;charset=utf-8',
    Authorization: `Bearer ${getJwt()}`,
    'Cache-Control': 'no-cache',
  }
}
