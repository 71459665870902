import { authReducer } from './auth'
import { FinancialReducer } from './financial'
import { MetaTraderUsersReducer } from './metaTraderUsers'
import { modalReducer } from './modals'
import { rightBarReducer } from './rightBar'
import { ServersMtReducer } from './servers'
import { UsersReducer } from './users'

export const root = {
  auth: authReducer,
  modal: modalReducer,
  rightBar: rightBarReducer,
  servers: ServersMtReducer,
  users: UsersReducer,
  financial: FinancialReducer,
  metaTraderUsers: MetaTraderUsersReducer,
}
