import axios, { AxiosResponse } from 'axios'
import { IMetaTraderUsers } from '../models/IMetaTraderUsers'
import { localAsUTC } from '../utils/time-utils'
import { api, buildHeaders } from './apiUtils'

export class MetaTraderUsersService {
  static async getMetaTraderUsers(
    pageSize?: string,
    PreviouslyLoaded?: string,
    ServerId?: string,
  ): Promise<AxiosResponse<{ items: IMetaTraderUsers[]; totalItems: number }>> {
    const url = new URL(`${api()}/Users`)
    url.searchParams.set('PageSize', pageSize ?? '250')
    url.searchParams.set('PreviouslyLoaded', PreviouslyLoaded ?? '0')
    url.searchParams.set('ServerId', ServerId ?? '0')
    return axios.get(`${url}`, {
      headers: buildHeaders(),
    })
  }

  static async editMetaTraderUsers(login: number, serverId: number, customId: string, dateOfBirth: Date | string): Promise<AxiosResponse<IMetaTraderUsers>> {
    return axios.put(
      `${api()}/Users`,
      { login, serverId, customId: customId ? customId : null, dateOfBirth: localAsUTC(new Date(dateOfBirth)) },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async searchMetaTraderUsers(login: string, serverId: string): Promise<AxiosResponse<IMetaTraderUsers>> {
    const url = new URL(`${api()}/Users/GetByLogin`)
    url.searchParams.set('ServerId', serverId)
    url.searchParams.set('UserLogin', login)
    return axios.get(`${url}`, {
      headers: buildHeaders(),
    })
  }
}
