import axios, { AxiosResponse } from 'axios'
import { IGetPermissions, IUsers } from '../models/IUsers'
import { api, buildHeaders } from './apiUtils'

export class UsersService {
  static async getUsers(PageSize?: string, PreviouslyLoaded?: string): Promise<AxiosResponse<{ users: IUsers[]; totalUsers: number }>> {
    const url = new URL(`${api()}/Identity/Users`)
    url.searchParams.set('PageSize', PageSize ?? '250')
    url.searchParams.set('PreviouslyLoaded', PreviouslyLoaded ?? '0')
    return axios.get(`${url}`, {
      headers: buildHeaders(),
    })
  }

  static async addUser(user: IUsers): Promise<AxiosResponse<{ login: string }>> {
    return axios.post(
      `${api()}/Identity/Users`,
      { ...user },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async changePermissions(user: IUsers): Promise<AxiosResponse<{ login: string; missions: string[] }>> {
    return axios.put(
      `${api()}/Identity/Users`,
      { ...user },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async changePassword(user: { [key: string]: string }): Promise<AxiosResponse<IUsers>> {
    return axios.put(
      `${api()}/Identity/Users/ChangePassword`,
      { ...user },
      {
        headers: buildHeaders(),
      },
    )
  }

  static async deleteUser(login: string): Promise<AxiosResponse<{ login: string }>> {
    return axios.delete(`${api()}/Identity/Users/${login}`, {
      headers: buildHeaders(),
    })
  }

  static async getPermissions(): Promise<AxiosResponse<IGetPermissions[]>> {
    return axios.get(`${api()}/Identity/Permissions`, {
      headers: buildHeaders(),
    })
  }
}
