import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

interface IMultiSelectInput {
  state: any
  name: string
  options: any[]
  className?: string
  style?: any
  touched?: any
  errors?: any
  errorText?: string
  label?: string
  isDisabled?: boolean
  isCreatable?: boolean
  placeholder?: string
  tooltip?: string
  tooltipFlag?: boolean

  setState(state: any): void

  setTouched?(touched: any): void

  onCreateOption?(value: any): void
}

const MultiSelectInput: React.FC<IMultiSelectInput> = props => {
  const {
    state,
    tooltip,
    tooltipFlag,
    setState,
    options,
    label,
    name,
    isDisabled,
    isCreatable,
    className,
    touched,
    setTouched,
    errors,
    errorText,
    style,
    onCreateOption,
    placeholder,
  } = props

  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const element = isCreatable ? (
    <CreatableSelect
      isMulti={true}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      onCreateOption={onCreateOption}
      value={state[name]}
      placeholder={placeholder}
    />
  ) : (
    <Select
      isMulti={true}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      value={state[name]}
      placeholder={placeholder}
      isDisabled={!!isDisabled}
      styles={style ?? undefined}
    />
  )
  const isInvalid = touched && touched[name] && errors && errors[name]

  const renderTooltip = (props?: any, tooltip?: string) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  return (
    <>
      {tooltipFlag ? (
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
          <Form.Group className={className}>
            {label ? (
              <Form.Label>
                <FormattedMessage id={label} tagName="span" />
                <span>:</span>
              </Form.Label>
            ) : null}
            {element}
            <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
              <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
            </div>
          </Form.Group>
        </OverlayTrigger>
      ) : (
        <Form.Group className={className}>
          {label ? (
            <Form.Label>
              <FormattedMessage id={label} tagName="span" />
              <span>:</span>
            </Form.Label>
          ) : null}
          {element}
          <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
            <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
          </div>
        </Form.Group>
      )}
    </>
  )
}
export default MultiSelectInput
