import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { FC, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AppNavbar, AppSideBar, ScrollTopButton } from 't4b-core-frontend/lib'
import { api } from './api/apiUtils'
import { CurrentTime } from './components/AppNavBar/CurrentTime'
import { NavUser } from './components/AppNavBar/NavUser'
import { APP_LANGS, appLogo, messages } from './components/AppNavBar/utils'
import { AppRouter } from './components/AppRouter'
import { AppSideBarItem } from './components/AppSideBar/AppSideBarItem'
import { useActions } from './hooks/useActions'
import { useTypedSelector } from './hooks/useTypedSelector'
import { ModalContainer } from './modals/ModalContainer'
import { RightBarContainer } from './rightBar/RightbarContainer'

export const ScrollContext = React.createContext<any>(null)

export const App: FC = memo(() => {
  const { showModal, logout, config, getVersion } = useActions()
  const { isAuth } = useTypedSelector(state => state.auth)
  const scrollRef: RefObject<OverlayScrollbarsComponent> = useRef(null)
  const [currentLang, onLangChange] = useState(localStorage.getItem('lang') || 'en')
  const [sidebarHidden, setSidebarHidden] = useState(true)
  const [, setTimeStamp] = useState(0)
  const location = useLocation()

  useEffect(() => {
    config()
  }, [])

  axios.interceptors.request.use(response => {
    if (response.url === `${api}/Auth/RefreshToken` && (response.data.refreshToken === undefined || response.data.userName === undefined)) {
      logout()
    }
    return response
  })

  const handleLangChange = (lang: string) => {
    localStorage.setItem('lang', lang)
    onLangChange(lang)
  }

  const handleSidebarClick = useCallback(() => {
    setSidebarHidden(!sidebarHidden)
  }, [sidebarHidden])

  const onLicenseClick = useCallback(async () => {
    const version = await getVersion()
    showModal('LICENSE', { version })
  }, [])

  return (
    <>
      <IntlProvider locale={currentLang} messages={messages[currentLang]}>
        {useMemo(
          () => (
            <AppNavbar
              logo={appLogo}
              i18nLangs={APP_LANGS}
              currentLangKey={currentLang}
              onLangChange={handleLangChange}
              isAuthenticated={isAuth}
              onSidebarHandlerClick={handleSidebarClick}
              sidebarHidden={sidebarHidden}
              navbarItemsRight={[<CurrentTime key={2} />]}
              exitButton={<NavUser />}
            />
          ),
          [sidebarHidden, isAuth, currentLang, APP_LANGS],
        )}
        <div className="d-flex flex-row position-relative" style={{ background: '#ecf5fd' }}>
          {isAuth && (
            <AppSideBar
              sidebarItems={AppSideBarItem()}
              logo={faBars}
              progressItems={[]}
              sidebarHidden={sidebarHidden}
              currentLocation={location.pathname}
              isScrollable={false}
              onLicenceClick={onLicenseClick}
            />
          )}
          <OverlayScrollbarsComponent
            ref={scrollRef}
            style={{ height: 'calc(100vh - 58px)', width: '100%' }}
            options={{
              callbacks: {
                onScrollStop: (event: any) => setTimeStamp(event.timeStamp),
              },
            }}
          >
            <ScrollContext.Provider value={scrollRef?.current?.osInstance() || {}}>
              <div
                className="App"
                style={{
                  padding: '30px',
                  height: 'calc(100vh - 58px)',
                  width: '100%',
                }}
              >
                <AppRouter />
              </div>
              <div className="scroll-wrp">
                <ScrollTopButton top={300} flag={true} context={scrollRef?.current?.osInstance()} />
              </div>
            </ScrollContext.Provider>
          </OverlayScrollbarsComponent>
          <RightBarContainer />
          <ModalContainer />
          <ToastContainer autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </IntlProvider>
    </>
  )
})
