import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { AuthService } from '../../../api/AuthService'
import { throwErrorMessage } from '../../../utils/errors-utils'
import { AppDispatch } from '../../index'
import {
  getJwt,
  getRefreshToken,
  getUser,
  removeApiPath,
  removeJwt,
  removeRefreshToken,
  removeUser,
  removeUserRole,
  saveApiPath,
  saveJwt,
  saveRefreshToken,
  saveUser,
  saveUserRole,
} from './authUtils'
import { AuthActionEnum, DeleteAuthAction, SetAuthAction } from './types'

export const AuthActionCreators = {
  setAuthorized: (): SetAuthAction => ({ type: AuthActionEnum.AUTHORIZED }),
  deleteAuthorized: (): DeleteAuthAction => ({
    type: AuthActionEnum.UNAUTHORIZED,
  }),

  config: () => async () => {
    try {
      const { data } = await AuthService.getConfig()
      await saveApiPath(data.apiPath)
    } catch (error: unknown) {}
  },

  getVersion: () => async () => {
    try {
      const { data } = await AuthService.getVersion()
      return data.bbiWebApiVersion
    } catch (error: unknown) {}
  },

  login: (username: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await AuthService.getUsers(username, password)
      const { token, userName, refreshToken, permissions } = data
      await saveJwt(token)
      await saveUser(userName)
      await saveRefreshToken(refreshToken)
      await saveUserRole(permissions)
      dispatch({ type: AuthActionEnum.AUTHORIZED })
    } catch (error: unknown) {
      throwErrorMessage('Incorrect login or password')
    }
  },

  logout: () => async (dispatch: AppDispatch) => {
    await removeUserRole()
    await removeJwt()
    await removeUser()
    await removeRefreshToken()
    await removeApiPath()
    dispatch({ type: AuthActionEnum.UNAUTHORIZED })
  },
}

const refreshToken = async (failedRequest: any) => {
  const { data } = await AuthService.refreshToken(getUser(), getRefreshToken())
  const { token, refreshToken } = data
  await saveJwt(token)
  await saveRefreshToken(refreshToken)
  failedRequest.response.config.headers.Authorization = `Bearer ${token}`
}

axios.interceptors.request.use((response: any) => {
  response.headers.Authorization = `Bearer ${getJwt()}`
  return response
})

createAuthRefreshInterceptor(axios, refreshToken)
