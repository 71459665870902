import React from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'

interface ISearchableSelectInput {
  state: any
  name: string
  options: any
  touched?: any
  errors?: any
  errorText?: string
  label?: string
  isDisabled?: boolean
  className?: string
  styles?: any
  placeholder?: string
  prefix?: string

  setState(newState: any): any
  onClick?(event: any): any
  setTouched?(touched: any): any
}

const SearchableSelectInput: React.FC<ISearchableSelectInput> = ({
  state,
  setState,
  name,
  options,
  label,
  className,
  styles,
  isDisabled,
  onClick,
  placeholder,
  errors,
  errorText,
  touched,
  setTouched,
  prefix,
}) => {
  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const isInvalid = touched && touched[name] && errors && errors[name]

  return (
    <Form.Group className={className} onClick={onClick}>
      {label && (
        <Form.Label>
          <FormattedMessage id={label} tagName="span" />:
        </Form.Label>
      )}
      <Select
        styles={styles}
        isSearchable={true}
        options={
          prefix
            ? options.map((item: any) => {
                return {
                  value: item.value,
                  label: <FormattedMessage id={`${prefix}.${item.value}`} />,
                }
              })
            : options
        }
        name="symb"
        value={state[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
      <div className={`invalid-feedback ${isInvalid && 'd-block'}`}>
        <FormattedMessage id={errorText || 'field-validation-error'} tagName="span" />
      </div>
    </Form.Group>
  )
}

export default React.memo(SearchableSelectInput)
