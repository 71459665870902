import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useActions } from '../../hooks/useActions'
import { getUser } from '../../store/reducers/auth/authUtils'

export const NavUser: React.FC = () => {
  const { logout, config } = useActions()

  const onSignOut = () => {
    logout()
    config()
  }
  const title = (
    <span className="color-white">
      <span className="mr-1 font-500">{getUser()}</span>
      <FontAwesomeIcon icon={faUserCircle} size="lg" />
    </span>
  )
  return (
    <NavDropdown title={title} id="nav-dropdown" className="nav-user d-flex align-items-center">
      <NavDropdown.Item onClick={onSignOut}>
        <FormattedMessage id="sign-out" />
      </NavDropdown.Item>
    </NavDropdown>
  )
}
