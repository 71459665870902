import { ServersService } from '../../../api/ServersService'
import { IServersItemId } from '../../../models/IServers'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import { AppDispatch } from '../../index'
import { ServersMtActionEnum } from './types'

export const ServersActionCreators = {
  getMtServers: (PageSize?: string, PreviouslyLoaded?: string, setLoading?: (cb: boolean) => void) => async (dispatch: AppDispatch) => {
    try {
      if (!setLoading) {
        const { data } = await ServersService.getServers(PageSize, PreviouslyLoaded)
        dispatch({ type: ServersMtActionEnum.SET_SERVERSMT, payload: data })
      } else {
        setLoading(true)
        const { data } = await ServersService.getServers(PageSize, PreviouslyLoaded)
        dispatch({ type: ServersMtActionEnum.SET_SERVERSMT, payload: data })
        setLoading(false)
      }
    } catch (error: unknown) {}
  },
  getMtServersItemId: (id: string) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ServersService.getServersItemId(id)
      dispatch({ type: ServersMtActionEnum.SET_SERVERSITEMID, payload: data })
    } catch (error: unknown) {}
  },
  modifyMtServersItemId: (item: IServersItemId) => async () => {
    try {
      await ServersService.modifyServersItemId(item)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  addMtServersItemId: (item: IServersItemId) => async () => {
    try {
      await ServersService.addServersItemId(item)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  reloadMtServersItemId: (id: string, date: Date) => async () => {
    try {
      await ServersService.reloadServersItemId(id, date)
      throwSuccessMessage('Successfully')
    } catch (error) {
      throwErrorMessage(error?.response?.data[0])
    }
  },
  getTimezones: () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ServersService.getTimezones()
      dispatch({ type: ServersMtActionEnum.SET_TIMEZONE, payload: data })
    } catch (error: unknown) {}
  },
}
