import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { AppAccordion } from 't4b-core-frontend/lib'
import { Users } from '../entity/Users'
import { useActions } from '../hooks/useActions'
import { useFormValidation } from '../hooks/useFormValidation'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { buildControlsExtTwoPerLine, mselectInput, passwordInput, textInput } from '../inputs/controls'
import { IMultiSelectItem } from '../models/IMultiSelectItem'
import { buildMultiselectOptionsFromArray } from '../utils/buildSelectOptions'
import { IUsers } from '../models/IUsers'

interface IProps {
  data: {
    user: IUsers
    type: string
    setLoading: (cb: boolean) => void
  }
}

export const UsersSettings: FC<IProps> = ({ data: { user, type, setLoading } }) => {
  const { addUser, getUsers, hideRightBar, changePermissions, changePassword } = useActions()
  const { permissions } = useTypedSelector(state => state.users)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(new Users(user), {})

  const inputs = buildControlsExtTwoPerLine(
    [
      textInput('login').holder('Login'),
      passwordInput('password').setMode(type).holder('Password'),
      passwordInput('newPassword')
        .holder('New password')
        .setMode(type)
        .disabled(type === 'add'),
      mselectInput('permissions', buildMultiselectOptionsFromArray(permissions)).holder('Select permissions'),
    ],
    inputState,
    setInputState,
    'UserRightBar',
    touched,
    setTouched,
    errors,
  )

  const handleSave = async () => {
    const bodyAdd = {
      login: inputState.login,
      password: inputState.password,
      permissions: inputState.permissions.map((item: IMultiSelectItem) => item.value),
    }
    const bodyPermissions = {
      login: inputState.login,
      permissions: inputState.permissions.map((item: IMultiSelectItem) => item.value),
    }

    const bodyPassword = {
      login: inputState.login,
      currentPassword: inputState.password,
      newPassword: inputState.newPassword,
    }

    if (type === 'modify') {
      if (inputState.newPassword?.length) {
        changePassword(bodyPassword)
      }
      await changePermissions(bodyPermissions)
    } else {
      await addUser(bodyAdd)
    }
    await getUsers('250', '0', setLoading)
    await hideRightBar()
  }

  return (
    <>
      <AppAccordion
        item={{
          title: <FormattedMessage id={`UserRightBar.title.${type}`} />,
          item: inputs,
        }}
        isHidden={false}
      />

      <Button className="t4b-bg-dark-button mt-3 ml-20" onClick={handleSave}>
        <FormattedMessage id="save" tagName="span" />
      </Button>
    </>
  )
}
