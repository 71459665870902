export interface AuthState {
  isAuth: boolean
}

export enum AuthActionEnum {
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface SetAuthAction {
  type: AuthActionEnum.AUTHORIZED
}

export interface DeleteAuthAction {
  type: AuthActionEnum.UNAUTHORIZED
}

export type AuthAction = SetAuthAction | DeleteAuthAction
